export default {
  api: {
    operationSucceed: 'Aktion erfolgreich',
    operationFailed: 'Aktion fehlgeschlagen',
    errorTip: 'Fehlerhinweis',
    errorMessage: 'Aktion fehlgeschlagen, Systemfehler!',
    timeoutMessage: 'Zeitüberschreitung beim Einloggen, bitte erneut einloggen!',
    apiTimeoutMessage:
      'Zeitüberschreitung bei Port-Anfrage, bitte die Seite neuladen und es erneut versuchen!',
    apiRequestFailed: 'Anfragefehler, bitte später erneut versuchen',
    networkException: 'Netzwerkfehler',
    networkExceptionMsg: 'Netzwerkfehler, bitte die Netzwerkverbindung überprüfen!',

    errMsg401: 'Fehlende Nutzerberechtigung (Token-, Nutzername-, Passwortfehler)!',
    errMsg403: 'Nutzer hat Berechtigung erhalten, doch der Zugriff ist gesperrt!',
    errMsg404: 'Fehler bei Netzwerkanfrage, diese Ressource wurde nicht gefunden!',
    errMsg405: 'Fehler bei Netzwerkanfrage, Anfragemethode nicht erlaubt!',
    errMsg408: 'Zeitüberschreitung bei Netzwerkanfrage!',
    errMsg500: 'Serverfehler, bitte Administrator kontaktieren!',
    errMsg501: 'Netzwerk nicht implementiert!',
    errMsg502: 'Netzwerkfehler!',
    errMsg503:
      'Server nicht verwendbar, der Server ist vorübergehend ausgelastet oder wird gewartet!',
    errMsg504: 'Netzwerk-Zeitüberschreitung!',
    errMsg505: 'http-Version unterstützt diese Anfrage nicht!',
  },
  exception: {
    backLogin: 'Zurück zum Login',
    backHome: 'Zurück zur Hauptseite',
    subTitle403: 'Keine Berechtigung zum Aufrufen dieser Seite.',
    subTitle404: 'Die aufgerufene Seite existiert nicht.',
    subTitle500: 'Fehler bei Serverbericht',
    noDataTitle: 'Aktuelle Seite hat keine Daten',
    networkErrorTitle: 'Netzwerkfehler',
    networkErrorSubTitle: 'Die Netzwerkverbindung wurde unterbrochen, bitte Netzwerk überprüfen!',
  },
  app: {
    title: 'Startseite',
    description: 'Ace-Laden, Spiel-Aufladung, Dmen-Aufladung, Token-Aufladung',
    tip: 'Hinweis',
    copyright: 'Copyright©2021 ACE GAME',
    icp: 'Beijing ICP-Nr. 20009004',
    address: 'Firmenadresse: Beisanhuandonglu 28, Chaoyangqu, Beijing',
    account: 'Mein Account',
    changePwd: 'Passwort ändern',
    chargeRecord: 'Aufladeverlauf',
    helpCenter: 'Hilfe-Center',
    chargeNow: 'Jetzt aufladen',
  },
  login: {
    email: 'E-Mail-Adresse',
    verifyCode: 'Verifizierungscode',
    password: 'Passwort',
    passwordHolder: 'Passwort (Kombination aus 8-30 Ziffern und Buchstaben eingeben)',
    loginButton: 'Login',
    freeAccount: 'Kostenlose Registrierung',
    freeAccountCreate: 'Kostenlos Account erstellen',
    forgetPassword: 'Passwort vergessen?',
    findPassword: 'Passwort wiederherstellen',
    findEmailSent:
      'Eine E-Mail zur Passwort-Wiederherstellung wurde an deine E-Mail-Adresse gesendet, bitte klicke auf den Link in der E-Mail, um das Passwort zurückzusetzen.',
    forgetFormTitle: 'Passwort zurücksetzen',
    oldPassword: 'Altes Passwort',
    newPassword: 'Neues Passwort',
    repeatPassword: 'Bitte Passwort erneut eingeben',
    policyCheck: 'Zur Zustimmung abhacken',
    policy: '《Datenschutzvereinbarung von Ace Games》',
    hadAccount: 'Du hast bereits einen Account?',
    backSignIn: 'Zurück zum Login',

    logoutButton: 'Ausloggen',
    registerButton: 'Registrierung',

    otherSignIn: 'Andere Login-Methoden',

    // notify
    loginSucceed: 'Login erfolgreich',
    logoutSucceed: 'Du hast dich bereits mit diesem Account ausgeloggt',
    registerSucceed: 'Registrierung erfolgreich',
    changePwdSucceed: 'Ändern des Passworts erfolgreich',
    resetPwdSucceed: 'Zurücksetzen des Passworts erfolgreich',

    // ValidateMsg
    emailValidateMsg: 'Bitte korrekte E-Mail-Adresse eingeben',
    passwordValidateMsg: 'Kombination aus 8-30 Ziffern und Buchstaben eingeben',
    verifySentMsg: 'Verifizierungscode versendet',
    verifyValidateMsg: '6-stelligen Verifizierungscode eingeben',
    policyValidateMsg: 'Zum Registrieren zunächst abhacken',
    diffPwdValidateMsg: 'Die eingegebenen Passwörter stimmen nicht überein',
  },
  help: {
    helpModalTitle: 'Hilfe-Center',
    helpTipsTitle: 'Hinweis',
    helpTips_1:
      'Die auf dieser Plattform erhaltenen virtuellen Items können nur für "D-MEN: The Defenders" verwendet werden.',
    helpTips_2:
      'Bitte lese und überprüfe beim Kauf sorgfältig alle Informationen zum Namen, Preis, Anzahl, Spezifikationen, Produktdetails, Bestellungsablauf, Lieferung, Kontaktmethode und Zahlungsmethoden für das gekaufte Item. Bitte gebe persönliche Informationen nicht an Dritte weiter, um Verluste zu vermeiden.',
    serviceTitle: 'Leistungsbeschreibung',
    service_1:
      'Dieser Service ist nur für Nutzer, die "D-MEN: The Defenders" verknüpft haben. Nutzer, die die Verknüpfung nicht durchgeführt haben, müssen die Verknüpfung im Spiel abschließen, um diesen Service zu nutzen.',
    service_2:
      'Wichtig: Überprüfe nach Eingabe des Accounts bitte sorgfältig die angezeigten Account-Infos, um sicherzustellen, dass sie fehlerfrei sind, und führe erst dann den nächsten Schritt durch! (Sollte es aufgrund von fehlerhaften Accountdaten zu Zahlungsproblemen kommen, können wir keine Rückerstattungen durchführen, danke für dein Verständnis!)',
    service_3:
      'Nach dem Abschluss der Zahlung werden die virtuellen Items an deinen Auflade-Account gesendet, überprüfe den Erhalt im Spiel. Wenn du die gekauften virtuellen Items 30 Min. nach der Aufladung noch nicht erhalten hast, dann kontaktiere bitte unseren Kundendienst und wir werden das Problem so schnell wie möglich beheben.',
    contactTitle: 'Kundendienst-Kanalliste',
    contactEmail: 'Kundendienst-E-Mail-Adresse',
    contactFacebook: 'Facebook-Link',
    contactDiscord: 'Discord-Link',
  },
  charge: {
    title: 'Item-Liste',
    description: 'Ace-Laden, Spiel-Aufladung, Dmen-Aufladung, Token-Aufladung',
    coinSelect: 'Währung auswählen',
    merchandiseList: 'Spielitem-Liste',
    buyNow: 'Sofortkauf',
    quickLimit: 'Zeitlimitierter Sofortkauf',
    buyLimit: 'Kauf von {limit} Item(s) pro Charakter erlaubt',
    chooseRole: 'Charakter auswählen',
    server: 'Server',
    serverIdHolder: 'Server-ID eingeben',
    roleID: 'Charakter-ID',
    roleIDHolder: 'Charakter-ID eingeben',
    searchResult: 'Suchergebnis',
    addRole: 'Charakter hinzufügen',
    roleInfo: 'Charakterinfo',
    roleLevel: 'Lv {level}',
    gameName: 'Spielname',
    action: 'Aktion',
    confirmDel: 'Wirklich löschen?',
    paymentWay: 'Zahlungsmethode',
    paymentAmount: 'Zahlungssumme',
    roleLimit: 'Verbleibende Käufe für diesen Charakter: ',
    payNow: 'Jetzt zahlen',
    payJumpTips: 'Wechseln auf Drittanbieter-Zahlungsplattform, bitte kurz warten ...',
    paid: 'Zahlung abgeschlossen',
  },
  orders: {
    title: 'Bestellungsliste',
    description: 'Bestellungsliste',
    ordersTitle: 'Bestellungsliste',
    searchDate: 'Datum überprüfen',
    selectGame: 'Spiel auswählen',
    allGame: 'Alle Spiele',
    orderNo: 'Bestellungsnummer',
    gameName: 'Spielname',
    merchandiseName: 'Item-Name',
    merchandiseDetail: 'Item-Info',
    orderAmount: 'Bestellungssumme',
    createAt: 'Erstellungszeit',
    orderStatus: 'Bestellungsstatus',
    statusGoing: 'Zahlung im Gange',
    statusSucceed: 'Zahlung erfolgreich',
    statusUnknown: 'Bitte gehen Sie zum Aufladen des Beitrags, um das Zahlungsergebnis anzuzeigen',
    statusFailed: 'Zahlung fehlgeschlagen',
    action: 'Aktion',
    viewDetail: 'Details ansehen',
    detailTitle: 'Bestellungsdetails',
    backToOrders: 'Zurück zur Bestellungsliste',
    orderInfo: 'Bestellungsinfo',
    merchandiseInfo: 'Item-Info',
    price: 'Einzelpreis',
    quantity: 'Anzahl',
    discount: 'Angebot',
    payResult: 'Zahlungsergebnis',
    viewOrder: 'Bestellung ansehen',
  },
  order: {
    title: 'Bestellungsdetails',
    description: 'Bestellungsdetails',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: 'Zahlungsergebnis',
    description: 'Zahlungsergebnis',
  },
};
